@font-face {
  font-family: "RubikRegular";
  src: url("./assets/fonts/Rubik-Regular.woff2") format("woff2"),
    url("./assets/fonts/Rubik-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: "RubikRegular";
}

body {
  background-color: #f2f2f2;
}

/* Loading gears */
#wrapper {
  width: 100%;
  max-width: 725px;
  margin: 0px auto;
}

.gears {
  /* width: 48%; */
  display: inline-block;
}
.gears-container {
  width: 150px;
  height: 150px;
  font-size: 24px;
  padding: 9%;
  position: relative;
  margin: 0px auto;
}

.gear-rotate {
  width: 2em;
  height: 2em;
  top: 42%;
  left: 35%;
  margin-top: -1em;
  margin-left: -1em;
  background: #1664a5;
  position: absolute;
  border-radius: 1em;
  -webkit-animation: 1.2s gear-rotate linear infinite;
  -moz-animation: 1.2s gear-rotate linear infinite;
  animation: 1.2s gear-rotate linear infinite;
}
.gear-rotate-left {
  margin-top: -2.2em;
  top: 81%;
  width: 2em;
  right: 15.2%;
  height: 2em;
  background: #1664a5;
  position: absolute;
  border-radius: 1em;
  animation: 1.2s gear-rotate-left linear infinite;
}

.gear-rotate::before,
.gear-rotate-left::before {
  width: 2.8em;
  height: 2.8em;
  background: -webkit-linear-gradient(
      0deg,
      transparent 39%,
      #1664a5 39%,
      #1664a5 61%,
      transparent 61%
    ),
    -webkit-linear-gradient(60deg, transparent 42%, #1664a5 42%, #1664a5 58%, transparent
          58%),
    -webkit-linear-gradient(120deg, transparent 42%, #1664a5 42%, #1664a5 58%, transparent
          58%);
  background: -moz-linear-gradient(
      0deg,
      transparent 39%,
      #1664a5 39%,
      #47ec19 61%,
      transparent 61%
    ),
    -moz-linear-gradient(60deg, transparent 42%, #1664a5 42%, #1664a5 58%, transparent
          58%),
    -moz-linear-gradient(120deg, transparent 42%, #1664a5 42%, #1664a5 58%, transparent
          58%);
  background: -o-linear-gradient(
      0deg,
      transparent 39%,
      #1664a5 39%,
      #1664a5 61%,
      transparent 61%
    ),
    -o-linear-gradient(60deg, transparent 42%, #1664a5 42%, #1664a5 58%, transparent
          58%),
    -o-linear-gradient(120deg, transparent 42%, #47ec19 42%, #1664a5 58%, transparent
          58%);
  background: -ms-linear-gradient(
      0deg,
      transparent 39%,
      #1664a5 39%,
      #1664a5 61%,
      transparent 61%
    ),
    -ms-linear-gradient(60deg, transparent 42%, #1664a5 42%, #1664a5 58%, transparent
          58%),
    -ms-linear-gradient(120deg, transparent 42%, #1664a5 42%, #1664a5 58%, transparent
          58%);
  background: linear-gradient(
      0deg,
      transparent 39%,
      #1664a5 39%,
      #1664a5 61%,
      transparent 61%
    ),
    linear-gradient(
      60deg,
      transparent 42%,
      #1664a5 42%,
      #1664a5 58%,
      transparent 58%
    ),
    linear-gradient(
      120deg,
      transparent 42%,
      #1664a5 42%,
      #1664a5 58%,
      transparent 58%
    );
  position: absolute;
  content: "";
  top: -0.4em;
  left: -0.4em;
  border-radius: 1.4em;
}
.gear-rotate::after,
.gear-rotate-left::after {
  width: 1em;
  height: 1em;
  background: #fff;
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.5em;
}

/*
 * Keyframe Animations 
 */

@-webkit-keyframes gear-rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-180deg);
  }
}

@-moz-keyframes gear-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-180deg);
  }
}

@keyframes gear-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-180deg);
  }
}

@-webkit-keyframes gear-rotate-left {
  0% {
    -webkit-transform: rotate(30deg);
  }
  100% {
    -webkit-transform: rotate(210deg);
  }
}

@-moz-keyframes gear-rotate-left {
  0% {
    -webkit-transform: rotate(30deg);
  }
  100% {
    -webkit-transform: rotate(210deg);
  }
}

@keyframes gear-rotate-left {
  0% {
    -webkit-transform: rotate(30deg);
  }
  100% {
    -webkit-transform: rotate(210deg);
  }
}

/* Data loader */
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #1664a5;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* button loader */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 72px;
  height: 8px;
}
.lds-ellipsis div {
  position: absolute;
  top: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #1664a5;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.form-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 3px solid #1664a5;
  padding: 32px;
  border-radius: 15px;
}

.form-wrapper-title {
  position: absolute;
  top: -30px;
  left: 28px;
  font-size: 24px;
  color: #1664a5;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  background-color: #f2f2f2;
  padding: 8px;
}

.form-wrapper label {
  font-size: 16px;
  text-transform: uppercase;
  color: #1664a5;
  padding-left: 6px;
  padding-top: 12px;
}

.form-wrapper input,
.form-wrapper textarea {
  width: 100%;
  color: #1664a5;
  border: 2.2px solid #1664a5;
  border-radius: 8px;
  background-color: #fff;
  padding: 4px 8px;
}

.form-wrapper input:focus,
.form-wrapper textarea:focus {
  outline: none;
}

.form-wrapper button {
  color: #fff;
  border: 2.2px solid #1664a5;
  border-radius: 8px;
  background-color: #1664a5;
  text-transform: uppercase;
  transition: all 0.3s;
  margin-top: 24px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding: 2px 24px;
}

.form-wrapper button:hover {
  color: #1664a5;
  background-color: #fff;
}

.form-wrapper button:disabled {
  opacity: 0.5;
  background-color: #f2f2f2 !important;
}

.error-message {
  color: #c20323;
  font-style: italic;
  margin: 0;
  padding-top: 12px;
}

.form-wrapper .es {
  color: #f2f2f2;
  background-image: linear-gradient(to right, rgb(255, 38, 0), #deec19);
  margin: 24px 0 0 0;
  padding: 4px 4px;
  width: fit-content;
}

.form-wrapper .fr {
  color: #f2f2f2;
  background-image: linear-gradient(to right, #002654, #ED2939 70%);
  margin: 24px 0 0 0;
  padding: 4px 4px;
  width: fit-content;
}

.side-menu {
  background-color: #1664a5;
  border-radius: 0 15px 15px 0;
  min-height: 100vh !important;
}

.side-menu ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.side-menu a {
  display: inline-block;
  padding: 40px 0;
  text-decoration: none;
  color: #f2f2f2;
  font-size: 20px;
  text-transform: uppercase;
  padding-left: 12px;
  width: 100%;
  transition: all 0.5s;
  border-bottom: 2px solid rgb(40, 39, 39);
}

.side-menu a.active {
  box-shadow: rgb(40, 39, 39) -8px 0 8px 2px;
}

.side-menu li:last-child a {
  border-bottom: none;
}

.side-menu a:hover {
  box-shadow: rgb(40, 39, 39) -8px 0 8px 2px;
}

.logout-button {
  padding: 40px 0;
  display: flex;
  justify-content: center;
}

.logout-button button {
  border: none;
  border-radius: 12px 0 12px 0;
  background-color: #c20323;
  color: #f2f2f2;
  padding: 10px 30px;
  transition: all 0.6s;
}

.logout-button button:hover {
  background-color: #f2f2f2;
  color: #c20323;
}

.front {
  z-index: 999;
}

.cover {
  object-fit: cover;
}

.lightgray-bg {
  background-color: #f2f2f2;
}

.delete-button {
  position: absolute;
  top: 4px !important;
  left: 85% !important;
  border-radius: 50% !important;
  width: 22px !important;
  height: 22px !important;
  border: 2px solid #c20323 !important;
  box-shadow: #aaa7a7 0px 1px 5px 0px !important;
  cursor: pointer;
  transform: translateY(0);
  transition: all 0.4s;
  filter: blur(0);
  padding: 0 !important;
  margin: 0 !important;
  background-color: #f2f2f2 !important;
}

.delete-button:hover {
  transform: translateY(1px);
}

.data-container {
  background-color: #d8d8d8;
  border-radius: 15px;
  box-shadow: #6c757d 0px 3px 6px -2px;
}

.data-container table {
  border-collapse: separate;
  border-spacing: 20px;
}

.data-container th {
  text-transform: capitalize;
  font-size: 20px;
  line-height: 25px;
  color: #1664a5;
  vertical-align: top;
  max-width: 250px;
}

.data-container td {
  font-size: 18px;
  word-break: break-word;
  overflow-wrap: break-word;
}

.data-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
}

.delete-button-general {
  color: #f2f2f2 !important;
  background-color: #c20323 !important;
  border-radius: 8px !important;
  border: none !important;
  padding: 8px 12px !important;
  text-transform: uppercase !important;
  transition: all 0.6s !important;
}

.delete-button-general:hover {
  background-color: #98011a !important;
}

.edit-button-general {
  color: #f2f2f2;
  background-color: #1664a5;
  border-radius: 8px;
  border: none;
  padding: 8px 12px;
  text-transform: uppercase;
  min-width: 82px;
  transition: all 0.6s;
}

.edit-button-general:hover {
  background-color: #0f4775;
}

.cancel-button-general {
  color: #1664a5;
  background-color: #f2f2f2;
  border-radius: 8px;
  border: none;
  padding: 8px 12px;
  text-transform: uppercase;
  min-width: 82px;
  transition: all 0.6s;
}

.cancel-button-general:hover {
  background-color: #cbcbcb;
}

.delete-modal-container {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;
  transition: all 0.8s;
}

.delete-modal-container.show {
  opacity: 1;
  pointer-events: all;
}

.delete-modal-small-container {
  background-color: #1664a5;
  margin: auto;
  border-radius: 15px;
}

.delete-modal-small-container h3 {
  color: #f2f2f2;
  font-size: 24px;
}

.pointer {
  cursor: pointer;
}

.pagination-container {
  list-style: none;
  display: flex;
  padding: 0;
}

.pagination-container li {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
  margin-right: 6px;
}

.pagination-container a {
  text-decoration: none;
  color: #1664a5 !important;
  padding: 3px 10px;
  margin-bottom: 0;
  width: 100%;
  height: 100%;
  transition: all 0.4s;
  border-radius: 8px;
}

.pagination-container a:hover {
  background-color: #1664a5;
  color: #f2f2f2 !important;
}

.pagination-previous {
  color: #f2f2f2;
}

.pagination-next {
  color: #f2f2f2;
}

.pagination-active {
  background-color: #1664a5;
  color: #f2f2f2 !important;
  border-radius: 8px !important;
}

.pagination-active a {
  color: #f2f2f2 !important;
}

.pagination-disabled {
  opacity: 0.4 !important;
}

.pagination-disabled a:hover {
  background-color: #f2f2f2 !important;
  color: #1664a5 !important;
  cursor: not-allowed;
}

.w-200 {
  width: 200px;
}

.link-no-styles {
  text-decoration: none;
  color: #f2f2f2;
}

.link-no-styles:hover {
  color: #f2f2f2;
}

.upload__image-wrapper {
  width: 100%;
  color: #1664a5;
  border: 2.2px solid #1664a5;
  border-radius: 8px;
  background-color: #fff;
  padding: 4px 8px;
}

.image-item {
  border-top: 2.2px solid #1664a5;
}

.products-images h3 {
  text-transform: capitalize;
  font-size: 20px;
  line-height: 25px;
  color: #1664a5;
  vertical-align: top;
  max-width: 250px;
  font-weight: bold;
}

.success-message {
  font-style: italic;
  color: #0f4470;
}
